/** @jsx jsx */
import { jsx } from 'theme-ui'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Markdown from '../components/Markdown'

export default function IndexPage(props) {
  const { t } = useTranslation()

  return (
    <Layout {...props}>
      <SEO title="Home" />
      <main className="block">
        <section className="py-14 md:py-24" id={t('legal.id')}>
          <div className="mx-auto px-container max-w-container">
            <h2 className="text-headline2 leading-none text-center">
              {t('legal.headline')}
            </h2>
            <div>
              <Markdown
                className="mt-14 text-headline4"
                source={t('legal.imprint')}
              />
              <Markdown className="mt-14" source={t('legal.privacy')} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
